import { redirect } from '../../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';
import { trackMixpanelBeforeRedirectToCheckout } from '../../helper-function/trackMixpanelBeforeRedirectToCheckout';

export function initSelectSubscriptionPeriod() {
    // Vars
    const inputItemCode = document.querySelector('[data-item-code]');
    const periodForm = document.querySelector('[data-subscribe-mobile-period-form]');
    const periodList = document.querySelectorAll('[data-input-radio]');

    // Listener
    periodList.forEach(function (period) {
        period.addEventListener('change', handleSelectedPeriod, false);
    });
    periodForm.addEventListener('submit', handleSubmitForm, false);

    // Handlers
    function handleSubmitForm(event) {
        event.preventDefault();
        redirect(periodForm, trackMixpanelBeforeRedirectToCheckout);
    }

    function handleSelectedPeriod() {
        updateSubscriptionPeriod(this);
    }

    // Functions
    function updateSubscriptionPeriod(form) {
        inputItemCode.value = form.value;
        updateButtonAttributes(form.value);
    }

    function updateButtonAttributes(selectedValue) {
        switch (selectedValue) {
            case 'um_mf1_50':
                periodForm.setAttribute('data-gtm-add-to-cart', '1');
                periodForm.setAttribute('data-mixpanel-plan-type', '1_month');
                break;
            case 'um_mf3_90':
                periodForm.setAttribute('data-gtm-add-to-cart', '3');
                periodForm.setAttribute('data-mixpanel-plan-type', '3_months');
                break;
            case 'um_mf12_150':
                periodForm.setAttribute('data-gtm-add-to-cart', '12');
                periodForm.setAttribute('data-mixpanel-plan-type', '12_months');
                break;
            default:
                console.warn('Unknown subscription value:', selectedValue);
        }
    }
}
