import anime from 'animejs/lib/anime.es.js';

export function initSelectsubscribePlan() {
    const toggles = document.querySelectorAll('[data-subscrebi-toggle]');
    if (!toggles) return;
    const activeClass = '--is-open-subscribe';
    const duration = 300;
    const isDesktop = window.innerWidth > 1280;

    if (isDesktop) {
        return;
    }

    toggles.forEach((toggle) => {
        toggle.addEventListener('click', handleOnClick, false);
    });

    function handleOnClick() {
        const accordion = this.closest('[data-subscrebi]');
        const toggles = document.querySelectorAll('[data-subscrebi-body]');

        toggles.forEach((toggle) => {
            if (toggle.classList.contains(activeClass)) {
                closeAccordion(toggle);
            }
        });

        openAccordion(accordion);
    }

    function openAccordion(accordion) {
        const body = accordion.querySelector('[data-subscrebi-body]');
        const height = body.scrollHeight;
        body.classList.toggle(activeClass);

        anime({
            targets: body,
            height: height,
            easing: 'linear',
            duration: duration,
            begin: function () {
                body.style.height = 'auto';
            },
        });
    }

    function closeAccordion(accordion) {
        const height = accordion.scrollHeight;

        anime({
            targets: height,
            height: 0,
            easing: 'linear',
            duration: duration,
            update: function (anim) {
                accordion.style.height = anim.progress > 10 ? height * (1 - anim.progress / 100) + 'px' : height + 'px';
            },
            complete: function () {
                accordion.style.height = '0';
                accordion.classList.toggle(activeClass);
            },
        });
    }
}
