import { initRedirectToCheckout } from '../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';
import { initSelectsubscribePlan } from '../components/initSelectsubscribePlan';
import { initStickySubscribePlans } from '../components/initStickySubscribePlans';
import { setCurrentStyleSubscription } from '../components/initStickyTableHeader';
import { initSelectSubscriptionPeriod } from '../components/forms/initSelectSubscriptionPeriod';
import { initRenderPricesPage } from '../components/initRenderPricesPage';
import { initMobileFeatureMenu } from '../components/initMobileFeatureMenu';
import { initAppendHiddenInputsToPlanForms } from '../../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';

window.addEventListener('DOMContentLoaded', handleOnDOMContentLoaded);

function handleOnDOMContentLoaded() {
    initAppendHiddenInputsToPlanForms();
    initRenderPricesPage();
    initMobileFeatureMenu();
    initRedirectToCheckout();
    initSelectsubscribePlan();
    initStickySubscribePlans();
    setCurrentStyleSubscription();
    initSelectSubscriptionPeriod();
}
