export function initStickySubscribePlans() {
    // Vars
    const header = document.querySelector('[data-header-sticky]');
    const scrollHeader = document.querySelector('[data-scroll-subscribe]');
    const activeClass = '--sticky-umobix-subscribe';

    // Listener
    window.addEventListener('scroll', handleScroll, false);

    // Handlers
    function handleScroll() {
        showStickySubscribePlans();
    }

    // Functions
    function showStickySubscribePlans() {
        if (window.scrollY > header.scrollHeight + header.offsetTop + 180) {
            scrollHeader.classList.add(activeClass);
        } else {
            scrollHeader.classList.remove(activeClass);
        }
    }
    showStickySubscribePlans();
}
