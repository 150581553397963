export const initStickyTableHeader = () => {
    // VARS
    const tableHeader = document.querySelector('[data-table-header]');
    const stickyTableHeader = document.querySelector('[data-sticky-table-header]');
    const table = document.querySelector('[data-table]');
    if (!tableHeader || !stickyTableHeader || !table) return;
    let coords = getCoords(table);
    let tableHeaderCoords = tableHeader.getBoundingClientRect();

    // LISTENERS AND CALLS
    window.addEventListener('scroll', handleOnScrollWindow, false);
    window.addEventListener('resize', handleOnResizeWindow, false);

    toggleSticky();

    // HANDLERS
    function handleOnScrollWindow() {
        toggleSticky();
    }

    function handleOnResizeWindow() {
        setCurrentStyle();
    }

    // FUNCTIONS
    function toggleSticky() {
        if (window.pageYOffset > coords.top + 20 && window.pageYOffset + 220 < coords.bottom) {
            stickyTableHeader.classList.add('is-sticky');
            stickyTableHeader.style.left = tableHeaderCoords.left + 'px';
            stickyTableHeader.style.width = tableHeaderCoords.width + 'px';
        } else {
            stickyTableHeader.classList.remove('is-sticky');
            stickyTableHeader.style.left = '';
            stickyTableHeader.style.width = '';
        }
    }

    function setCurrentStyle() {
        coords = getCoords(table);
        tableHeaderCoords = tableHeader.getBoundingClientRect();
        stickyTableHeader.style.left = tableHeaderCoords.left + 'px';
        stickyTableHeader.style.width = tableHeaderCoords.width + 'px';
    }

    function getCoords(elem) {
        const box = elem.getBoundingClientRect();

        return {
            top: box.top + pageYOffset,
            left: box.left + pageXOffset,
            bottom: box.bottom + pageYOffset,
            right: box.right + pageXOffset,
        };
    }
};

export function setCurrentStyleSubscription() {
    // Vars
    const table = document.querySelector('[data-set-attribute]');

    // Listener
    window.addEventListener('resize', handleOnResizeWindow, false);

    // Handlers
    function handleOnResizeWindow() {
        resizeTablet();
    }

    // Functions
    function resizeTablet() {
        table.setAttribute('data-sticky-table-header', '');
        initStickyTableHeader();
    }
    resizeTablet();
}
